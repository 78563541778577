<script setup lang="ts">
const colorMode = useColorMode()

const isDark = computed({
    get() {
        return colorMode.value === 'dark'
    },
    set() {
        colorMode.preference = colorMode.value === 'dark' ? 'light' : 'dark'
    },
})

const items = computed(() => [
    [
        {
            label: 'Riza Masykur',
            avatar: {
                src: 'https://github.com/benjamincanac.png',
            },
            type: 'label',
        },
    ],
    [
        {
            label: 'Profile',
            icon: 'i-lucide-user',
        },
        {
            label: 'Settings',
            icon: 'i-lucide-cog',
            // kbds: [','],
        },
        {
            label: ` ${
                colorMode.preference === 'light' ? 'Dark' : 'Light'
            } Mode`,
            icon:
                colorMode.preference === 'light'
                    ? 'i-lucide-moon'
                    : 'i-lucide-sun',
            onSelect() {
                colorMode.preference =
                    colorMode.value === 'dark' ? 'light' : 'dark'
            },
            onClick: () => {
                colorMode.preference =
                    colorMode.value === 'dark' ? 'light' : 'dark'
            },
            // kbds: ['shift', 'meta', 'd'],
        },

        {
            label: 'Support',
            icon: 'i-lucide-life-buoy',
            to: '/components/dropdown-menu',
        },
    ],
    [
        {
            label: 'Logout',
            icon: 'i-lucide-log-out',
            to: '/logout',
            // kbds: ['shift', 'meta', 'q'],
        },
    ],
])
</script>

<template>
    <UDropdownMenu
        :items="items"
        :ui="{
            content: 'w-48',
        }"
    >
        <UButton
            class="cursor-pointer"
            icon="material-symbols:account-circle-full"
            color="neutral"
            variant="ghost"
        />
    </UDropdownMenu>
</template>

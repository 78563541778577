<template lang="pug">
.mainbody
    //- BaseSidebar
    main.mainbox
        .w-full.sticky.top-0.left-0.z-5(
        )
            .w-vw.sticky.top-0.left-0.z-5.border-b.border-b-zinc-200(
                class="h-[60px] max-h-[60px] dark:border-b-neutral-600/40"
            )
                .flex.flex-1.h-full.px-4.gap-4
                    .flex.self-center(
                        class="min-w-[auto]"
                    )
                        BaseLogoSm
                            
                    .flex.self-center.flex-1.justify-start.overflow-x-auto(
                    )
                        UNavigationMenu.hidden(
                            class="md:block"    
                            orientation="horizontal" :items="items" class="data-[orientation=vertical]:w-48"
                        )
                        .flex.self-center(
                            class="md:hidden"
                        )
                            UDropdownMenu(
                                :items="items"
                                :content="{align: 'start',side: 'bottom',sideOffset: 8,}"
                                :ui="{content: 'w-full'}"
                            )
                                UButton(
                                    icon="material-symbols:menu"
                                    color="gray"
                                    variant="soft"
                                )

                    .flex.self-center.gap-2
                        BaseNavbarNotification
                        NavbarMenu

            slot
//- ++++++++++++++++++++++++++
//- Base Color Mode
//- ++++++++++++++++++++++++++
ColorMode
</template>
<script setup lang="ts">
defineProps<{
    title?: string
}>()

const items = ref([
    [
        {
            label: 'Buat Pesanan',
            icon: 'material-symbols:add',
            to: '/template/salesorder/create',
        },
        {
            label: 'Riwayat Pesanan',
            icon: 'material-symbols:stacked-line-chart-rounded',
            to: '/template/salesorder',
        },
        {
            label: 'Toko Saya',
            icon: 'bi:inboxes',
            to: '/template/mystore',
        },
        {
            label: 'Pengaturan',
            icon: 'material-symbols:settings',
            to: '/template/settings',
        },
    ],
])
</script>
